export const deviceMotionFunction = (event) => {
    return {
        strong: Math.floor(Math.abs(event.acceleration.x * 2) + Math.abs(event.acceleration.y * 2)),
        lastNumR: event.acceleration.x + event.acceleration.y
    };
};

// deshabilitado lo de orientación porque tenían que girar demasiado el telefono
// export const deviceOrientationFunction = (event) => {
//     return {
//         strong: Math.floor(Math.abs(event.beta) + Math.abs(event.gamma)),
//         lastNumR: event.beta + event.gamma
//     };
// };

export const dustAnimation = () => {
    const pinataToPlay = document.getElementById('pinatatoplay');
    const particles = 7;

    for (let particle = 0; particle < particles; particle++) {
        const span = document.createElement("span");
        span.classList.add("fiery");
        span.innerHTML = "✹";
        span.style.bottom = (Math.random() * (33 - 25) + 25) + "%";
        span.style.left = (Math.random() * (77 - 24) + 24) + "%";
        span.style.fontSize = (Math.random() * (25 - 4) + 4) + "px";
        pinataToPlay.appendChild(span);
    }
};

export const removeSpans = (event) => {
    const pinataObject = document.getElementById('pinatatoplay');
    pinataObject.removeChild(event.srcElement);
};
