
export const prizeCatalog = [
    '',
    'Amazon',
    'Amazon',
    'Tip',
    'Receta',
    '',
    '',
    '',
    'Spotify'
];
