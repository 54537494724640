import React from 'react';
import { GetRecipe, MainLogo } from '../../constants/assets';

const Recipe = (props) => {
    const { number, goToPlaySection } = props;
    const links = [
        {
            title: 'Bolita de plátano con queso',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/bolita-de-platano-con-queso'
        }, {
            title: 'Bolitas cremosas con chorizo',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/bolitas-cremosas-con-chorizo'
        }, {
            title: 'Bolitas de papa con jamón',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/bolitas-de-papa-con-jamon'
        }, {
            title: 'Brochetas de camarón rebozado con salsa de mango',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/brochetas-de-camaron-rebozado'
        }, {
            title: 'Brownie',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/brownie'
        }, {
            title: 'Camarones Orly con salsa Roquefort',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/camarones-orly-con-salsa-roquefort'
        }, {
            title: 'Chuleta de cerdo con costra y salsa de chabacano y chipotle',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/chuleta-de-cerdo-con-costra'
        }, {
            title: 'Crema de camarón',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/crema-de-camaron'
        }, {
            title: 'Crema de champiñones',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/crema-de-champinones'
        }, {
            title: 'Crepas de achiote',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/crepas-de-achiote'
        }, {
            title: 'Croquetas de papa',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/croquetas-de-papa'
        }, {
            title: 'Croquetas de pollo, requesón y verduras',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/croquetas-de-pollo-requeson-y-verduras'
        }, {
            title: 'Deditos de pescado',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/deditos-de-pescado'
        }, {
            title: 'Dedos de papa con queso',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/dedos-de-papa-con-queso'
        }, {
            title: 'Ensalada de espinacas con fajitas de pollo, aguacate, mango y almendras',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/ensalada-de-espinaca-con-fajitas-de-pollo'
        }, {
            title: 'Filete de pescado en salsa de cítricos',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/filete-de-pescado-en-salsa-de-citricos'
        }, {
            title: 'Filete de pescado Tres Estrellas',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/filete-de-pescado-tres-estrellas'
        }, {
            title: 'Leche frita de coco con frutas',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/leche-frita-de-coco-con-frutas'
        }, {
            title: 'Pay de verduras',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/pay-de-verduras'
        }, {
            title: 'Pechuga de pollo a la napolitana',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/pechugas-de-pollo-a-la-napolitana'
        }, {
            title: 'Pescado a la inglesa',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/pescado-a-la-inglesa'
        }, {
            title: 'Plátanos fritos con salsa tres leches y fresas',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/platanos-fritos-con-salsa-tres-leches-y-fresas'
        }, {
            title: 'Pollo crujiente',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/pollo-crujiente'
        }, {
            title: 'Rosquitas Tres estrellas',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/rosquitas-tres-estrellas'
        }, {
            title: 'Tempura de chile ancho de cilantro relleno de salpicón de pollo',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/tempura-de-chile-ancho'
        }, {
            title: 'Torre de crepas poblanas',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/torre-de-crepas-poblanas'
        }, {
            title: 'Tortas de arroz con salsa de aguacate',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/tortas-de-arroz-con-salsa-de-aguacate'
        }, {
            title: 'Tortitas de atún',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/tortitas-de-atun'
        }, {
            title: 'Aros crujientes de manzana con canela',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/aros-crujientes-de-manzana'
        }, {
            title: 'Esferas crujientes de arroz con jamón de pavo y queso manchego',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/esferas-crujientes-de-arroz'
        }, {
            title: 'Hamburguesa de atún con verduras',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/hamburguesa-de-atun-con-verduras'
        }, {
            title: 'Pescado empanizado con amaranto y ajonjolí',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/pescado-empanizado-amaranto-ajonjoli'
        }, {
            title: 'Galletas de harina de arroz con canela y amaranto',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/galletas-de-harina-de-arroz-con-canela-y-amaranto'
        }, {
            title: 'Tamales canarios dulces',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/tamales-canarios-tradicionales'
        }, {
            title: 'Tempura de chocolate',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/tempura-de-chocolate'
        }, {
            title: 'Tiritas de pescado empanizadas con pepita de calabaza',
            url: 'https://tres-estrellas.com/nuestras-recetas/harina-de-arroz-receta/tiritas-de-pescado-empanizadas'
        }
    ];
    const randomNum = number ? number : Math.floor(Math.random() * 36) + 1;
    // const getUrl = window.location;
    // const urlToShare = encodeURIComponent(`${getUrl.origin}/prize-winned/recipe/${randomNum}`);
    const urlToShare = encodeURIComponent(links[randomNum - 1].url);

    const shareOnFB = () => {
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + urlToShare, "_blank");
    };

    return (
        <div className="recipe-section">
            <button onClick={goToPlaySection} className="close-top-button"></button>
            <h1 className="comillas">Receta<span>{links[randomNum - 1].title}</span></h1>
            <GetRecipe number={randomNum} />
            <div className="recipe-buttons">
                <a href={links[randomNum - 1].url} target="_blank" rel="noopener noreferrer">Ver receta</a>
            </div>
            <div className="recipe-sphere"></div>
            <MainLogo customClass="free-prize-logo" />
            <button onClick={shareOnFB} className="share-button"></button>
        </div>
    );
};

export default Recipe;
