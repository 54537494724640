import React, { Component } from 'react';
import BottomButtonsNew from '../../shared/BottomButtonsNew/BottomButtonsNew';
import { dinamicaCerrada, mensajeCerrada } from '../../constants/config';

class Intro extends Component {
    componentDidMount(){
        document.body.classList.remove("texture-2");
    }

    getRandomImage = () => {
        const int = Math.floor(Math.random() * 4) + 1;
        return (
            <img className="intro-receta-png" src={
                require(`../../assets/images/introR${int}.png`)
            } alt={`Receta ${int}`} />
        );
    }

    goToNext = () => {
        const { history } = this.props;
        history.push("/play");
    }

    render() {
        return (
            <div className="welcome-page">
                { dinamicaCerrada && <p className="main-title closed-dinamic font-jellee">
                    {mensajeCerrada}
                </p> }
                { !dinamicaCerrada && <p className="main-title font-jellee">
                    Descubre recetas,<br/>
                    tips de cocina<br/>
                    <span className="font-asap">
                        y conoce más sobre los<br />
                        beneficios de cocinar con
                    </span><br />
                    Harina de Arroz<br />
                    Tres Estrellas.
                </p> }

                { !dinamicaCerrada && <img className="img-text" src={require('../../assets/images/intro-text.png')} alt="¡Participa y gana increíbles premios!" /> }

                { this.getRandomImage() }

                { !dinamicaCerrada && <BottomButtonsNew
                    btn1={{
                        btnAction: this.goToNext,
                        customClass: 'next-button-new'
                    }}
                /> }
            </div>
        );
    }
};

export default Intro;
