import React, { Component } from 'react';
import { connect } from 'react-redux';
import MessageScreen from '../../shared/MessageScreen/MessageScreen';
import Pinata from './Pinata';
import { MainLogo } from '../../constants/assets';
import { fbLogin } from '../../helpers/facebookLogin';
// import { fbLogin, fbCheckLogin } from '../../helpers/facebookLogin';
import { getCookie } from '../../helpers';
import { mapStateToProps, mapDispatchToProps } from '../../reducers/_dispatchers';
import { getPrize } from '../../actions/getPrizeActions';
import { FaFacebook, FaSpinner } from 'react-icons/fa';
import { Xplosion } from '../../constants/assets';
import { actionRegisterIn } from '../../actions/loginActions';

class Play extends Component {
    constructor(props) {
        super(props);
        const { Account: { isLogged, playedDate, tomorrow, playedTimes } } = props;
        const urlParams = new URLSearchParams(props.location.search);
        const code = urlParams.get('code')

        this.state = {
            isLoading: code ? true : false,
            isLoggined: isLogged || false,
            isLoadingToPlay: true,
            gameStartedBefore: false,
            gameStarted: false,
            playedDate,
            tomorrow,
            playedTimes,
            motionDetected: false,
            isMotionStyle: true,
            winned: false,
            code
        };
    }

    componentWillMount() {
        const today = new Date();
        const { tomorrow } = this.state;
        const playedDate = today.getTime();
        const tomorrowB = new Date();
        tomorrowB.setDate(tomorrowB.getDate() + 1);
/*  Ahorita son 24 horas después de haber jugado...
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayTomo = new Date();
        console.log(yesterday.getTime(), yesterdayTomo.getTime(), tomorrow);
*/

        if(playedDate >= tomorrow) {
            this.setState({
                playedTimes: 0,
                playedDate: playedDate,
                tomorrow: tomorrowB.getTime()
            });
            this.props.resetDate();
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            Account: { isLogged, errorLogin, windedPrize },
            setError
        } = nextProps;
        if(isLogged || errorLogin) {
            const isLoggined = isLogged || false;
            if(errorLogin) {
                setError();
            }
            this.setState({
                isLoggined,
                isLoading: false
            });
        }
        if(windedPrize
        && typeof windedPrize === 'object'
        && typeof windedPrize.promocion === 'object') {
            this.setState({
                isLoadingToPlay: false
            });
        }
    }

    componentDidMount(){
        const { code } = this.state;
        document.body.classList.add("texture-2");
        if(code) {
            let ckData = decodeURI(getCookie('fb_login_data'));
            ckData = ckData.replace(/%3A/g, ':');
            ckData = ckData.replace(/%40/g, '@');
            ckData = ckData.replace(/%2C/g, ',');
            ckData = ckData.replace(/\+/g, ' ');
            ckData = JSON.parse(ckData);
            actionRegisterIn({
                id: ckData.user_id,
                email: ckData.email,
                name: ckData.name
            });
            // fbCheckLogin(code);
        }
    }

    playGame = () => {
        const { Account } = this.props;
        const profileID = Account && Account.profile && Account.profile.social_id ? Account.profile.social_id : '';
        getPrize({
            profileID
        });
        this.setState({
            gameStarted: true
        });
    }

    startGameBefore = () => {
        this.setState({
            gameStartedBefore: true
        });
    }

    winTheGame = () => {
        this.setState({
            gameStarted: false,
            winned: true
        });
        setTimeout(() => {
            this.props.history.push('/prize-winned');
        }, 650);
    }

    runFbLogin = () => {
        this.setState({
            isLoading: true
        });
        fbLogin(true);
    }

    changeMode = () => {
        this.setState({
            isMotionStyle: false,
            motionDetected: true,
        });
    }

    motionAlreadyDetected = () => {
        this.setState({
            motionDetected: true,
        });
    }

    render() {
        const {
            isLoading,
            isLoggined,
            isLoadingToPlay,
            isMotionStyle,
            gameStarted,
            gameStartedBefore,
            motionDetected,
            playedTimes,
            winned
        } = this.state;
        const isLoadingBtn = isLoading ? <FaSpinner /> : '';
        const isLoadingBtnClass = isLoading ? 'loadin-button' : '';
        const mainTitle = motionDetected ? (
            isMotionStyle ? 'Agita tu celular' : 'Mueve la caja con tu dedo') : '...iniciando';
        const playinContainerClass = gameStarted ? 'playing-now' : '';
        const xplosionClass = winned ? 'winned' : '';
        const theRealContent = playedTimes < 5 ? (
            <div className={`play-page text-center ${playinContainerClass}`}>
                {!winned && !isLoadingToPlay && gameStarted && <h1 className="play-main-title-h1 font-jellee">{mainTitle}</h1>}
                { /*!gameStarted &&
                    <p>¡Rompe la piñata y conoce los sabores de estas fiestas!</p>
                */ }
                {!winned && !gameStarted && <img
                    className="play-big-image-1"
                    src={require('../../assets/images/piensa.png')}
                    alt="Piensa fuera de la caja"
                />}
                {!winned && !gameStarted && <p className="intro-play-text font-asap">
                    para preparar deliciosos<br />
                    platillos, botanas y postres.
                </p>}
                {!winned && isLoadingToPlay && gameStarted && <div className="loading-game"><FaSpinner /></div>}
                {!winned && !isLoadingToPlay && <Pinata
                    changeMode={this.changeMode.bind(this)}
                    gameStarted={gameStarted}
                    gameStartedBefore={gameStartedBefore}
                    motionAlreadyDetected={this.motionAlreadyDetected.bind(this)}
                    startGameBefore={this.startGameBefore.bind(this)}
                    winTheGame={this.winTheGame.bind(this)}
                    winned={winned} />
                }
                <Xplosion customClass={xplosionClass} />
                {!winned && !gameStarted && isLoggined &&
                    <div className="play-action-buttons">
                        <button
                            onClick={this.playGame}
                            disabled={isLoading}
                            className={`button play-button`}
                        ></button>
                    </div>
                }
                {!winned && !gameStarted && !isLoggined &&
                    <div className="play-action-buttons">
                        <button
                            onClick={this.runFbLogin}
                            disabled={isLoading}
                            className={`button fb-login ${isLoadingBtnClass}`}
                        ><span>
                            <FaFacebook />
                            <i>¡Regístrate para ganar!</i>
                        </span>{isLoadingBtn}</button>
                        <button
                            onClick={this.playGame}
                            disabled={isLoading}
                            className={`button play-button-text`}
                        >Juega sin registro</button>
                    </div>
                }
                {!winned &&
                    <MainLogo customClass="bottom-logo" />
                }
            </div>
        ) : <MessageScreen message="Regresa en 24 horas para intentarlo de nuevo." buttons={{logo:true}} history={this.props.history} />;

        return theRealContent;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Play);
