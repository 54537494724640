import React from 'react';

const Error404 = props => {
    const gotToHome = () => {
        const { history } = props;
        history.push('/');
    };

    return (
        <div className="error-404-page text-center">
            <h1>Página no encontrada</h1>
            <button onClick={gotToHome}>Ir a inicio</button>
        </div>
    );
};

export default Error404;
