import React from 'react';
import { GetPostal } from '../../constants/assets';
import cajaImage from  '../../assets/images/harina-tips.png';

const Postal = (props) => {
    const { number, goToPlaySection } = props;
    // const { number, urlImage, goToPlaySection } = props;
    // const urlToShare = encodeURIComponent(window.location.href);
    // const urlToShare = encodeURIComponent(urlImage);
    // const shareOnFB = () => {
    //     window.open('https://www.facebook.com/sharer/sharer.php?u=' + urlToShare, "_blank");
    // };

    return (
        <div className="postal-section tip">
            <button onClick={goToPlaySection} className="close-top-button"></button>
            <img src={cajaImage} className="header-tip-image" alt="Caja 3 Estrellas" />
            <GetPostal number={number} customClass="tipImage" />
            {/* <button onClick={shareOnFB} className="share-button"></button> */}
        </div>
    );
};

export default Postal;
