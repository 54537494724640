import { createStore, combineReducers } from 'redux';
import { reducerAccount } from './Account';

const reducer = combineReducers({
    Account: reducerAccount
});

export const STORE = createStore(
    reducer
);
