import React from 'react';
import laModernaLogo from'../assets/images/logoLmB.png';
import harinaX from '../assets/images/harinab.png';
import laModernaKit from '../assets/images/kit.png';
import laModernaTikets from '../assets/images/tickets.png';
import laModernaBookWined from '../assets/images/libro-ganado.png';
import kitGift from '../assets/images/kit.png';
import ticket from '../assets/images/tickets.png';
import amazon_0 from '../assets/images/amazon_0.png';
import amazon_1 from '../assets/images/amazon_1.png';
import netflix_0 from '../assets/images/netflix_0.png';
import netflix_1 from '../assets/images/netflix_0.png';
import spotify_0 from '../assets/images/spotify_0.png';
import spotify_1 from '../assets/images/spotify_1.png';

const unique = '?ref=2021-07-22_00:00';

const separeProps = (props) => {
    const { customClass, customStyle, customID } = props;
    return {
        customClass: customClass || '',
        customStyle: customStyle || {},
        customID: customID || ''
    };
};

export const MainLogo = (props) => {
    const { customClass, customStyle } = separeProps(props);
    return (
        <img src={laModernaLogo} className={customClass} style={customStyle} alt="Harina de Arroz, logo" />
    );
};

export const MainPinata = (props) => {
    const { customClass, customStyle, customID } = separeProps(props);
    return (
        <div id={customID} className={`main-pinata ${customClass}`} style={customStyle} />
    );
};

export const TiketsPrizeKitCinema = (props) => {
    const { customClass, customStyle } = separeProps(props);
    return (
        <img src={laModernaTikets} className={`tikets-prizekit-cinema ${customClass}`} style={customStyle} alt="Harina de Arroz, boletos de cine" />
    );
};

export const KitPrizeKitCinema = (props) => {
    const { customClass, customStyle } = separeProps(props);
    return (
        <img src={laModernaKit} className={`kit-prizekit-cinema ${customClass}`} style={customStyle} alt="Harina de Arroz, Kit de regalo" />
    );
};

export const SpotifyWined = (props) => {
    const { customClass, customStyle } = separeProps(props);
    return (
        <img src={laModernaBookWined} className={`kit-prizekit-wined-cinema ${customClass}`} style={customStyle} alt="Harina de Arroz, Libro de regalo" />
    );
};

export const PrizeCinemaWined = (props) => {
    const { customClass, customStyle } = separeProps(props);
    return (
        <div className="prize-wined-main-container">
            <h1 className="prize-wined-main-title">Boleto<span>de cine</span></h1>
            <img src={ticket} className={`cine-prizekit-wined-cinema ${customClass}`} style={customStyle} alt="Harina de arroz, Boletos de cine, regalo" />
            <p className="prize-wined-main-description font-asap">Completa el siguiente formulario y enviaremos tu <span className="font-jellee">Boleto doble de cine</span> a tu correo.</p>
        </div>
    );
};

export const PrizeKitWined = (props) => {
    const { customClass, customStyle } = separeProps(props);
    return (
        <div className="prize-wined-main-container">
            <h1 className="prize-wined-main-title">¡Muchas felicidades!</h1>
            <img src={kitGift} className={`kit-prizekit-wined-kit ${customClass}`} style={customStyle} alt="Harina de arroz, Kit de regalo" />
            <p className="prize-wined-main-description font-asap">Completa el siguiente formulario y enviaremos tu <span className="font-jellee">Kit Tres Estrellas</span> hasta la puerta de tu casa.</p>
        </div>
    );
};

export const TriviaQuestion = (props) => {
    const { pregunta } = props;
    const { customClass, customStyle } = separeProps(props);
    return (<h2 className={`triviaimage-prizekit-cinema ${customClass}`} style={customStyle}>{pregunta}</h2>);
};

export const GetPostal = (props) => {
    const { number } = props;
    const { customClass, customStyle } = separeProps(props);
    return (<img src={`${process.env.PUBLIC_URL}/tips/${number}.png${unique}`} className={`receta-imagecard-cinema ${customClass}`} style={customStyle} alt="Harina de Arroz, tip" />);
};
export const getPostalURL = (number) => {
    return `${process.env.PUBLIC_URL}/tips/${number}-face.png${unique}`;
};

export const GetRecipe = (props) => {
    const { number } = props;
    const { customClass, customStyle } = separeProps(props);
    return (<img src={`${process.env.PUBLIC_URL}/recetas/${number}.png${unique}`} className={`receta-imagecard-cinema ${customClass}`} style={customStyle} alt="Harina de Arroz, receta" />);
};

export const Xplosion = (props) => {
    const { customClass } = props;
    return (
        <div className={`pinata-xplosion ${customClass}`}>
            <img src={harinaX} className="caja" alt="Explosion de Harina de arroz" />
        </div>
    );
};

export const Amazon0 = () => <img src={amazon_0} className={`kit-prizekit-cinema`} alt="Harina de Arroz, tarjeta de amazon" />;
export const Amazon1 = () => <img src={amazon_1} className={`kit-prizekit-cinema`} alt="Harina de Arroz, tarjeta de amazon" />;
export const Netflix0 = () => <img src={netflix_0} className={`kit-prizekit-cinema`} alt="Harina de Arroz, tarjeta de netflix" />;
export const Netflix1 = () => <img src={netflix_1} className={`kit-prizekit-cinema`} alt="Harina de Arroz, tarjeta de netflix" />;
export const Spotify0 = () => <img src={spotify_0} className={`kit-prizekit-cinema spoty`} alt="Harina de Arroz, tarjeta de spotify" />;
export const Spotify1 = () => <img src={spotify_1} className={`kit-prizekit-cinema spoty`} alt="Harina de Arroz, tarjeta de spotify" />;
