import React from 'react';
import caja from '../../assets/images/piensa.png';

const Forms = (props) => {
    const { formCine, handleChange, prizeName } = props;

    return (
        <div className={`forms-page ${prizeName.toLowerCase().replace(/\s/g, '-')}`}>
            <fieldset>
                <label>Nombre</label>
                <input type="text" data-action="formCine|nombre" onChange={handleChange} value={formCine.nombre} />
                <label>Correo electrónico</label>
                <input type="text" data-action="formCine|correo" onChange={handleChange} value={formCine.correo} />
                <label>Celular</label>
                <input type="text" data-action="formCine|telefono" onChange={handleChange} value={formCine.telefono} />
            </fieldset>
            <small>*Tienes hasta 5 min. para llenar el formulario</small>
            <img src={caja} className='form-caja' alt='Harina de arroz' />
        </div>
    );
}

export default Forms;
