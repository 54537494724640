import React from 'react';
import BottomButtons from '../../shared/BottomButtons/BottomButtons';

const MessageScreen = (props) => {
    const { message, history, buttons } = props;
    const btn1 = buttons && buttons.btn1 ? buttons.btn1 : false;
    const btn2 = buttons && buttons.btn2 ? buttons.btn2 : false;
    const logo = buttons && buttons.logo ? buttons.logo : false;
    const customClass = !btn1 && !btn2 ? 'justify-bottom' : '';

    const goToPlaySection = () => {
        history.push('/');
    }

    return (
        <div className="message-screen-page text-center">
            <button onClick={goToPlaySection} className="close-top-button"></button>
            <h1>{message}</h1>
            <BottomButtons
                btn1={btn1}
                btn2={btn2}
                customClass={customClass}
                logo={logo}
            />
        </div>
    );
};

export default MessageScreen;
