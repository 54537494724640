import { HTTP } from '../core/http';
import { setError, setUser } from '../reducers/_dispatchers';

export const actionRegisterIn = (Data) => {
    const newData = {
        social_id: Data.id,
        social: 'facebook',
        correo: Data.email,
        nombre: Data.name,
    };
    HTTP({
        method: 'POST',
        data: newData,
        url: 'api/usuario/registrar_social'
    }).then((response) => {
        const responseData = response && response.data && response.data.data && response.data.status ? response.data.data : false;
        if(responseData) {
            setUser(responseData);
        } else {
            console.log(response);
            setError();
        }
    }).catch((error) => {
        console.log(error);
        setError();
    });
};
