import { STORE } from './Store';

export const setUser = (payload) => {
    STORE.dispatch({
        type: 'LOGIN',
        payload
    });
};
export const setLoggOut = () => {
    STORE.dispatch({
        type: 'LOGOUT'
    });
};
export const setError = () => {
    STORE.dispatch({
        type: 'ERROR'
    });
};
export const setPrize = (payload) => {
    STORE.dispatch({
        type: 'SETPRIZE',
        payload
    });
};
export const resetPrize = () => {
    STORE.dispatch({
        type: 'RESETPRIZE'
    });
};
export const resetDate = () => {
    STORE.dispatch({
        type: 'RESETDATE'
    });
};

export const mapStateToProps = (state) => {
    return {
        Account: state.Account
    }
};
export const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (payload) => { dispatch({type: 'LOGIN', payload}); },
        setLoggOut: () => { dispatch({type: 'LOGOUT'}); },
        setError: () => { dispatch({type: 'ERROR'}); },
        resetDate: () => { dispatch({type: 'RESETDATE'}); },
        resetPrize: () => { dispatch({type: 'RESETPRIZE'}); },
        setPrize: (payload) => { dispatch({type: 'SETPRIZE', payload}); },
    };
};
