import React, { Component } from 'react';
import Forms from '../../shared/Forms/Forms';
import {
    MainLogo,
    TriviaQuestion,
    Spotify1,
    Amazon1,
    Netflix1
} from '../../constants/assets';
import { map } from 'lodash';
import { sendTrivia } from '../../actions/sendTriviaActions';
import { validEmail } from '../../helpers/index';
// import { changeKit, changeTicket } from '../../actions/changePrizes';
import { changeTicket } from '../../actions/changePrizes';
import { FaSpinner } from 'react-icons/fa';
import { getPrizeImageStep1, getPrizeImageStep2 } from './helpers';
import { forceScreen, mockData } from '../../constants/config';

class PrizeKitCinema extends Component {
    constructor(props) {
        super(props);
        const { prizeName, playedTimes, profile, idPrize } = props;
        const socialID = profile && profile.social_id
            ? profile.social_id
            : (forceScreen
            ? '323423432432'
            : false);

        this.state = {
            socialID,
            answerSelected: '',
            idPrize,
            isLoading: false,
            isErrorFill: false,
            onStep: 1,
            playedTimes,
            prizeName,
            trivia: forceScreen
                ? mockData.trivia
                : props.trivia,
            triviaAnswered: true,
            winner: false,
            formSentOK: false,
            marcaAgua: '',
            errorMessage: '',

            errorFromService: false,
            errorServiceMessage: '',

            formCine: {
                social_id: socialID,
                correo: '',
                telefono: '',
                nombre: ''
            },
            formKit: {
                social_id: socialID,
                nombre: '',
                correo: '',
                celular: '',
                calle: '',
                codigo_postal: '',
                ciudad: '',
                colonia: '',
            }
        };
    }

    componentDidMount() {
        const { socialID } = this.state;
        if(!socialID) {
            this.props.goToPlaySection();
        }
    }

    goToNext = () => {
        // const { answerSelected, onStep, prizeName, idPrize, trivia, triviaAnswered, winner, formCine, formKit } = this.state;
        const { answerSelected, onStep, trivia, triviaAnswered, winner, formCine } = this.state;

        if(onStep === 2) {
            if(answerSelected !== '') {
                const { profile } = this.props;
                this.setState({ isLoading: true });
                sendTrivia({
                    answerSelected,
                    profile,
                    trivia,
                    endFunction: this.endSendFunction.bind(this),
                    errorFunction: this.errorFunction.bind(this)
                });
            } else {
                alert('Debes seleccionar una respuesta');
            }
        } else if(onStep === 3 && triviaAnswered && !winner) {
            this.props.goToPlaySection();
        } else if(onStep === 4) {
            const {
                social_id,
                correo,
                telefono,
                nombre
            } = formCine;

            if(!social_id || social_id === '') {
                this.setState({
                    isErrorFill: true,
                    errorMessage: 'Tu sesión ha caducado, no es posible enviar el formulario'
                });
            } else if(telefono === '' || telefono.length < 10) {
                this.setState({
                    isErrorFill: true,
                    errorMessage: 'El número telefónico debe ser de 10 dígitos'
                });
            } else if(nombre === '') {
                this.setState({
                    isErrorFill: true,
                    errorMessage: 'Escribe tu nombre'
                });
            } else if(!validEmail(correo)) {
                this.setState({
                    isErrorFill: true,
                    errorMessage: 'Escribe un correo válido'
                });
            } else if(social_id !== ''
            && correo !== ''
            && telefono !== ''
            && telefono.length === 10
            && nombre !== ''
            && validEmail(correo)) {
                this.setState({isLoading: true});
                changeTicket({
                    social_id,
                    correo: correo.trim().toLowerCase(),
                    telefono,
                    nombre,
                    endFunction: this.formSent.bind(this),
                    errorFunction: this.errorFunction.bind(this)
                });
            } else {
                this.setState({
                    isErrorFill: true,
                    errorMessage: 'Todos los campos son obligatorios, el número telefónico debe ser de 10 dígitos, y debes escribir un correo electrónico válido'
                });
            }

        } else {
            let nextStep = onStep + 1;
            nextStep = nextStep === 3 ? 4 : nextStep;
            this.setState({
                onStep: nextStep
            });
        }
    }

    errorFunction = (message) => {
        this.setState({
            errorFromService: true,
            errorServiceMessage: message
        });
    };

    endSendFunction = (Data) => {
        const { error } = Data;
        const { onStep } = this.state;
        let nextStep = onStep + 1;
        // nextStep = nextStep === 3 ? 4 : nextStep; por la pantalla que regreso el 26 de febrero, ver mas abajo....
        this.setState({
            isLoading: false,
            onStep: nextStep,
            triviaAnswered: true,
            winner: !error
        });
    }

    selectAnswer = (answer) => {
        this.setState({
            answerSelected: answer
        });
    }

    isNumber = (n) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

    handleChange = (event) => {
        const action = event.target.getAttribute('data-action').split('|');
        const name = action[1];
        const value = event.target.value;
        if(action[0] === 'formCine') {
            const { formCine } = this.state;
            if(name === 'telefono') {
                if((this.isNumber(value) || value === '') && value.length <= 10) {
                    formCine[name] = value;
                }
            } else {
                formCine[name] = value;
            }
            this.setState({
                formCine,
                isErrorFill: false
            });
        } else {
            const { formKit } = this.state;
            formKit[name] = value;
            this.setState({
                formKit,
                isErrorFill: false
            });
        }
    }

    formSent = ({microtime}) => {
        this.setState({
            formSentOK: true,
            marcaAgua: microtime
        });
    }

    setConfeti = () => {
        const confetiContainer = document.getElementById('confetiContenedor');
        confetiContainer.classList.add('confeti-class');
    }

    setErrorClass = () => {
        const confetiContainer = document.getElementById('confetiContenedor');
        confetiContainer.classList.add('error-class');
    }

    removeConfeti = () => {
        const confetiContainer = document.getElementById('confetiContenedor');
        confetiContainer.classList.remove('confeti-class');
    }

    restartPage = () => {
        window.location.reload();
    }

    render() {
        const {
            answerSelected,
            formSentOK,
            isErrorFill,
            isLoading,
            onStep,
            playedTimes,
            prizeName,
            trivia,
            winner,
            formCine,
            formKit,
            errorFromService,
            errorServiceMessage,
            marcaAgua,
            errorMessage
        } = this.state;

        const title = '¡Estás a<br>punto de ganar!';
        const titleClass = 'prize-wined-prev-main-title-libro';
        const instructionsText = onStep === 1 ? (
            <p className="instructionsText text-center">
                Contesta <strong>correctamente</strong> la<br />
                siguiente pregunta para<br />
                recibir tu <strong>premio</strong>.
            </p>
            ) : null;
        const prizeImage = onStep === 1 ? getPrizeImageStep1(prizeName) : null;
        const buttonClass = onStep === 1 || (onStep === 3 && winner) ? 'next-button' : (
            onStep === 2 ? 'square-send-button' : (
                onStep === 4 ? 'send-button' : (
                    onStep === 3 && playedTimes < 5 ?
                    'play-again-button' : ''
                )
            )
        );
        const disabled = isLoading;
        const isLoadingBtn = isLoading ? <FaSpinner /> : '';
        const isLoadingBtnClass = isLoading ? 'loadin-button' : '';
        const errorFillAllMsg = isErrorFill ? (<span className="error-fill">*{errorMessage}.</span>) : null;

        if((onStep === 3 && winner) || formSentOK){
            this.setConfeti();
        } else if(onStep === 4){
            this.removeConfeti();
            document.querySelector('body').classList.add('formulario');
        } else if(onStep === 3 && !winner){
            this.setErrorClass();
        } else {
            document.querySelector('body').classList.remove('formulario');
        }

        return formSentOK ? (
            <div className={`PrizeKitCinema ${prizeName} premio-ganado`}>
                <h1 className={`font-sep text-center ${titleClass} skew last-title`}>
                    <span>¡Felicidades</span><br />ganaste un monedero Chedraui!
                </h1>
                <button onClick={this.restartPage} className="close-top-button"></button>
                {getPrizeImageStep2(prizeName)}
                {/*<button className="button prize-kit-button play-again-button" onClick={this.props.goToPlaySection}></button>*/}
                <div className="marca-agua">{marcaAgua}</div>
                <div className="push-to-right">
                    <MainLogo customClass="prize-kit-logo" />
                </div>
            </div>
        ) : (
            <div className={`PrizeKitCinema ${prizeName} step-${onStep}`}>
                {!errorFromService && onStep === 1 && (
                    <div>
                        <h1 className={`font-sep text-center ${titleClass} skew`} dangerouslySetInnerHTML={{
                            __html: title,
                        }}></h1>
                        {/* que siempre no otra vez...
                        <button onClick={this.goToNext} className="close-top-button"></button>
                        */}
                        {prizeImage}
                        {instructionsText}
                    </div>
                )}

                {onStep === 3 && !winner && (
                    <div>
                        <button onClick={this.goToNext} className="close-top-button left"></button>
                        <img
                            className="play-big-image-1"
                            src={require('../../assets/images/piensa.png')}
                            alt="Piensa fuera de la caja"
                        />
                    </div>
                )}

                {!errorFromService && onStep === 2 && <TriviaQuestion pregunta={trivia.pregunta} />}
                {!errorFromService && onStep === 2 && map(trivia.json_opciones, (value, key) => {
                    const selectedClass = key === answerSelected ? 'selected' : '';
                    return (
                        <button
                            className={`answer-button ${selectedClass}`}
                            disabled={disabled}
                            key={`trivia-answer-${value}-${key}`}
                            onClick={()=>{
                                this.selectAnswer(key);
                            }}
                        >{value}</button>
                    );
                })}

                {errorFromService && errorServiceMessage !== '' && (
                    <div>
                        <h1 className="last-error-message">{errorServiceMessage}</h1>
                        <button className="button prize-kit-button play-again-button" onClick={this.props.goToPlaySection}></button>
                    </div>
                )}

                {/* Se elimino esta pantalla y pasa directo al 4, hoy 26 de feb del 2021, regresa esta pantalla */}
                {!errorFromService && onStep === 3 && winner &&
                    <h1 className={`font-sep text-center ${titleClass} skew`}>
                        ¡Respuesta correcta!
                    </h1>
                }
                {!errorFromService && onStep === 3 && winner && prizeName === 'Amazon' && <Amazon1 />}
                {!errorFromService && onStep === 3 && winner && prizeName === 'Netflix' && <Netflix1 />}
                {!errorFromService && onStep === 3 && winner && prizeName === 'Spotify' && <Spotify1 />}
                {!errorFromService && onStep === 3 && winner &&
                    <p className="text-center" style={{
                        color: 'white',
                        textShadow: '-1px -1px 0 #213b85'
                    }}>Date prisa y llena el siguiente formulario para que no pierdas tu <strong>premio</strong>.</p>
                }

                {onStep === 3 && !errorFromService && !winner && (
                    <div className={`PrizeKitCinema wrong-answer`}>
                        <img
                            className="play-big-image-1"
                            src={require('../../assets/images/piensa.png')}
                            alt="Piensa fuera de la caja"
                        />
                        <button onClick={this.props.goToPlaySection} className="close-top-button left"></button>
                        <h1 style={{position:'absolute', bottom: '70px'}}>Respuesta incorrecta ¡Sigue participando!</h1>
                    </div>
                )}

                {onStep === 4 && !errorFromService && winner && <Forms
                    formCine={formCine}
                    formKit={formKit}
                    handleChange={this.handleChange.bind(this)}
                    prizeName={prizeName} />}
                {onStep === 4 && errorFillAllMsg}

                {((winner && !errorFromService) || (onStep !== 4 && !errorFromService)) && (
                    <div>
                        <button
                            className={`button prize-kit-button ${buttonClass} ${isLoadingBtnClass}`}
                            disabled={disabled}
                            onClick={this.goToNext}>
                            {isLoadingBtn}
                        </button>

                        <div className="push-to-right">
                            <MainLogo customClass="prize-kit-logo" />
                        </div>

                    </div>
                )}
            </div>
        );
    }
}

export default PrizeKitCinema;
