import React from 'react';
import { prizeCatalog } from '../../constants/prizes';
import {
    Amazon0,
    Netflix0,
    Spotify0,
    Amazon1,
    Netflix1,
    Spotify1,
    // TiketsPrizeKitCinema, esto se va al helper...
    // KitPrizeKitCinema
} from '../../constants/assets';

export const getPrizeImageStep1 = prizeName => {
    let comp = null;
    switch (prizeName) {
        case prizeCatalog[1]:
            comp = <Amazon0 />
            break;
    case prizeCatalog[2]:
        comp = <Netflix0 />
        break;
    case prizeCatalog[8]:
        comp = <Spotify0 />
        break;
        default:
            break;
    }
    return comp;
}

export const getPrizeImageStep2 = prizeName => {
    let comp = null;
    switch (prizeName) {
        case prizeCatalog[1]:
            comp = <Amazon1 />
            break;
    case prizeCatalog[2]:
        comp = <Netflix1 />
        break;
    case prizeCatalog[8]:
        comp = <Spotify1 />
        break;
        default:
            break;
    }
    return comp;
}
