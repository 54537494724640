import { forceScreen } from '../constants/config';
import { HTTP } from '../core/http';

export const getMicroTime = (endFunction) => {
    HTTP({
        method: 'GET',
        data: {},
        url: 'api/pinata/get_date'
    }).then((response) => {
        if(response
        && typeof response.data === 'object'
        && typeof response.data.data !== 'undefined') {
            endFunction({
                error: false,
                microtime: response.data.data
            });
        } else {
            endFunction({
                error: false,
                microtime: 1598318538
            });
        }
    }).catch((error) => {
        endFunction({
            error: false,
            microtime: 1598318538
        });
    });
};

export const changeTicket = (Data) => {
    const {
        social_id,
        correo,
        telefono,
        nombre,
        endFunction,
        errorFunction
    } = Data;

    if(forceScreen) {
        getMicroTime(endFunction);
    }

    HTTP({
        method: 'POST',
        data: {
            social_id,
            correo,
            telefono,
            nombre
        },
        url: 'api/pinata/change_boleto'
    }).then((response) => {
        // const responseData = response && response.data && response.data.status ? true : false;
        // if(responseData) {
            getMicroTime(endFunction);
        // } else {
            // errorFunction('Error de servidor...');
        // }
    }).catch((error) => {
        let messageError = 'Error de servidor...';
        if(error && error.response && error.response.data && error.response.data.message) {
            messageError = error.response.data.message;
        }
        errorFunction(messageError);
    });
};

export const changeKit = (Data) => {
    const {
        idPrize,
        social_id,
        nombre,
        correo,
        celular,
        calle,
        codigo_postal,
        ciudad,
        colonia,
        endFunction,
        errorFunction
    } = Data;

    HTTP({
        method: 'POST',
        data: {
            social_id,
            nombre,
            correo,
            celular,
            calle,
            codigo_postal,
            ciudad,
            colonia,
            premio_id: idPrize
        },
        url: 'api/pinata/formulario'
    }).then((response) => {
        // const responseData = response && response.data && response.data.status ? true : false;
        // if(responseData) {
            endFunction({error: false});
        // } else {
            // errorFunction('Error de servidor...');
        // }
    }).catch((error) => {
        let messageError = 'Error de servidor...';
        if(error && error.response && error.response.data && error.response.data.message) {
            messageError = error.response.data.message;
        }
        errorFunction(messageError);
    });
};
