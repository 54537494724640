import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { dinamicaCerrada } from '../constants/config';

const GuardedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        dinamicaCerrada ? <Redirect to='/' /> : <Component {...props} />
    )} />
);

export default GuardedRoute;
