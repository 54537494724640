import React from 'react';
import { MainLogo } from '../../constants/assets';
import { FaFacebook, FaSpinner } from 'react-icons/fa';

const BottomButtons = props => {
    const {
        btn1,
        btn2,
        customClass,
        logo
    } = props;

    const btn1Action = () => {
        const { btnAction } = btn1;
        btnAction();
    }

    const btn2Action = () => {
        const { btnAction } = btn2;
        btnAction();
    }

    const isLoadingBtn1 = btn1 && btn1.isLoading ? <FaSpinner /> : '';
    const isLoadingBtn1Class = btn1 && btn1.isLoading ? 'loadin-button' : '';
    const isLoadingBtn1Disabled = btn1 && btn1.isLoading ? true : false;
    const isFacebookBtn1 = btn1 && btn1.customClass === 'fb-login' ? (
        <span>
            <FaFacebook />
            Registrar
        </span>
    ) : null;
    const isLoadingBtn2 = btn2 && btn2.isLoading ? <FaSpinner /> : '';
    const isLoadingBtn2Class = btn2 && btn2.isLoading ? 'loadin-button' : '';
    const isLoadingBtn2Disabled = btn2 && btn2.isLoading ? true : false;
    const isFacebookBtn2 = btn2 && btn2.customClass === 'fb-login' ? (
        <span>
            <FaFacebook />
            Registrar
        </span>
    ) : null;

    const btn1Enabled = btn1 ? (
        <button
            onClick={btn1Action}
            disabled={isLoadingBtn1Disabled}
            className={`button ${btn1.customClass} ${isLoadingBtn1Class}`}
        >{isFacebookBtn1}{isLoadingBtn1}</button>
    ) : null;

    const btn2Enabled = btn2 ? (
        <button
            onClick={btn2Action}
            disabled={isLoadingBtn2Disabled}
            className={`button ${btn2.customClass} ${isLoadingBtn2Class}`}
        >{isFacebookBtn2}{isLoadingBtn2}</button>
    ) : null;

    const logoEnabled = logo ? (
        <MainLogo customClass="bottom-logo" />
    ) : null;

    const realCustomClass = customClass || '';

    return (
        <div className={`bottom-buttons ${realCustomClass}`}>
            {btn1Enabled}
            {btn2Enabled}
            {logoEnabled}
        </div>
    );
};

export default BottomButtons;
