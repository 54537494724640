import { HTTP } from '../core/http';
import { setPrize } from '../reducers/_dispatchers';
import { forceScreen, mockData } from '../constants/config';

function setRandPrize() {
    const options = [
        {"promocion":{"id":"3","nombre":"Tip","condicion":"0","limite":null,"created_at":"2018-11-28 15:37:03"}},
        {"promocion":{"id":"4","nombre":"Receta","condicion":"0","limite":null,"created_at":"2018-11-28 15:37:06"}}
    ];
    const rand = Math.random();
    const num = rand < 0.5 ? Math.floor(rand) : Math.ceil(rand);
    return options[num];
}

export const getPrize = (Data) => {
    if(forceScreen) {
        setPrize(mockData[forceScreen]);
    } else {
        HTTP({
            method: 'GET',
            data: {},
            url: `api/pinata/get_trivia?social_id=${Data.profileID}`
            // url: `api/PinataTemporal/get_trivia?id_promo=2`
        }).then((response) => {
            const responseData = response && response.data && response.data.data && response.data.status ? response.data.data : false;
            if(responseData) {
                setPrize(responseData);
            } else {
                setPrize(setRandPrize());
            }
        }).catch((error) => {
            setPrize(setRandPrize());
            console.log(error);
        });
    }
};
