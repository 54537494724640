import React from 'react';
import { MainPinata } from '../../constants/assets';
import {
    deviceMotionFunction,
    // deviceOrientationFunction,
    dustAnimation,
    removeSpans
} from './movementFunctions';

const Pinata = ({
    changeMode,
    gameStarted,
    gameStartedBefore,
    motionAlreadyDetected,
    startGameBefore,
    winTheGame}) => {

    const pinataStyle = {};
    const classGameStarted = gameStarted ? 'game-started' : '';
    let pinataObject = null;
    let touchTimes = 0;
    let lastNum = 0;
    let timeR = 0;

    const endTheGame = () => {
        clearTimeout(window.ifNotWin);
        window.removeEventListener('devicemotion', thisDeviceMotionFunction);
        // window.removeEventListener('deviceorientation', thisDeviceOrientationFunction);
        window.removeEventListener('touchmove', thisDeviceOnTouchMoveFunction);
        winTheGame();
    };
    const thisDeviceMotionFunction = (event) => {
        if(timeR === 5) {
            const { strong, lastNumR } = deviceMotionFunction(event);
            lastNum = lastNumR;
            if(strong >= 120) {
                endTheGame();
            } else if (strong > 15) {
                // pinataObject.style.bottom = `${Math.floor(Math.random() * 57)}%`;
                pinataObject.style.left = `${Math.floor(-20 + Math.random() * (80 - 20))}%`;
                dustAnimation();
            }
        } else {
            timeR = timeR + 1;
        }
    };
    /* deshabilitado lo de orientación porque tenían que girar demasiado el telefono
    const thisDeviceOrientationFunction = (event) => {
        if(timeR === 5) {
            const { strong, lastNumR  } = deviceOrientationFunction(event);
            lastNum = lastNumR;
            if(strong >= 125) {
                endTheGame();
            } else if (strong > 20) {
                // pinataObject.style.bottom = `${Math.floor(Math.random() * 57)}%`;
                pinataObject.style.left = `${Math.floor(-20 + Math.random() * (80 - 20))}%`;
                dustAnimation();
            }
        } else {
            timeR = timeR + 1;
        }
    };
    */
    const thisDeviceOnTouchMoveFunction = () => {
        touchTimes = touchTimes + 1;
        // pinataObject.style.bottom = `${Math.floor(Math.random() * 57)}%`;
        pinataObject.style.left = `${Math.floor(-20 + Math.random() * (80 + 1 - 20))}%`;
        dustAnimation();
        if(touchTimes === 50) {
            endTheGame();
        }
    };
    const thisChangeIfStillOnZero = () => {
        if(lastNum === 0) {
            changeMode();
            window.removeEventListener('devicemotion', thisDeviceMotionFunction);
            // window.removeEventListener('deviceorientation', thisDeviceOrientationFunction);
            window.addEventListener('touchmove', thisDeviceOnTouchMoveFunction);
        } else {
            motionAlreadyDetected();
        }

        window.ifNotWin = setTimeout(() => {
            endTheGame();
        }, 7000);
    };
    const initListeners = () => {
        if(window.DeviceMotionEvent) {
            window.addEventListener('devicemotion', thisDeviceMotionFunction);
        } /* else if(window.DeviceOrientationEvent) {
            window.addEventListener('deviceorientation', thisDeviceOrientationFunction);
        } */

        pinataObject.addEventListener("webkitAnimationEnd", removeSpans, false);
        pinataObject.addEventListener("oanimationend", removeSpans, false);
        pinataObject.addEventListener("animationend", removeSpans, false);

        setTimeout(() => {
            startGameBefore();
        }, 0);
        setTimeout(() => {
            thisChangeIfStillOnZero();
        }, 400);
    };

    if(gameStarted && !gameStartedBefore) {
        setTimeout(() => {
            pinataObject = document.getElementById('pinatatoplay');
            initListeners();
        }, 300);
    }

    return <MainPinata customID="pinatatoplay" customClass={classGameStarted} customStyle={pinataStyle} />;
}

export default Pinata;
