import { forceScreen } from '../constants/config';
import { HTTP } from '../core/http';

export const sendTrivia = (Data) => {
    const {
        answerSelected,
        profile,
        trivia,
        endFunction,
        errorFunction
    } = Data;
    const socialID = profile && profile.social_id && profile.social_id !== '' ? profile.social_id : '';

    if(forceScreen) {
        endFunction({error: false});
        return;
    }

    HTTP({
        method: 'POST',
        data: {
            social_id: socialID,
            trivia_id: trivia.id,
            respuesta: answerSelected
        },
        url: 'api/pinata/validate_response'
    }).then((response) => {
        const responseData = response && response.data && response.data.status ? true : false;
        if(responseData) {
            endFunction({error: false});
        } else {
            endFunction({error: true});
        }
    }).catch((error) => {
        let messageError = 'Error de servidor...';
        if(error && error.response && error.response.data && error.response.data.message) {
            messageError = error.response.data.message;
        }
        errorFunction(messageError);
    });
};
