import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { mapDispatchToProps, mapStateToProps } from '../../reducers/_dispatchers';
import GuardedRoute from '../../core/guard';
import Error404 from '../404/404';
import Play from '../Play/Play';
import PrizeWinned from '../PrizeWinned/PrizeWinned';
import Intro from '../Intro/Intro';

class App extends Component {
    componentWillMount() {
        // Quitar comentario
        this.props.resetPrize(null);
    }

    render() {
        return (
            <section className="section-body">
                <Router>
                    <Switch>
                        <Route exact path="/" component={Intro}/>
                        <GuardedRoute path='/play' component={Play} />
                        <GuardedRoute exact path="/prize-winned/recipe/:recetaID?" component={PrizeWinned} />
                        <GuardedRoute exact path="/prize-winned/postal/:postalID?" component={PrizeWinned} />
                        <GuardedRoute path='/prize-winned' component={PrizeWinned} />
                        <Route component={Error404}/>
                    </Switch>
                </Router>
            </section>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
