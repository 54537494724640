import axios from 'axios';

export const HTTP = (conf) => {
    const data = conf.data;
    const headers = {
        'Cache-Control' : 'no-cache'
    };
    let formData;

    if(['PUT', 'DELETE'].indexOf(conf.method) > -1) {
        formData = data;
    } else {
        formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
    }

    const cHost = window.location.host;
    const hostUrl = cHost.indexOf('harinadearroz.com.mx') > -1
        ? 'https://services.harinadearroz.com.mx'
        : 'https://api-harinadearroz.heliko.space';

    return axios({
        method: conf.method,
        url: `${hostUrl}/${conf.url}`,
        headers,
        data: formData,
        // withCredentials: true,
        // credentials: 'same-origin',
        // responseType: 'text',
        // proxy: {
        //    host: 'http://wasser.heliko.space',
        //    port: 80
        // }
    });
};
