import React from 'react';
import { MainLogo } from '../../constants/assets';

const BottomButtonsNew = props => {
    const {
        btn1,
        customClass,
        logo
    } = props;

    const btn1Action = () => {
        const { btnAction } = btn1;
        btnAction();
    }

    const btn1Enabled = btn1 ? (
        <button
            onClick={btn1Action}
            disabled={btn1.disabled}
            className={`button ${btn1.customClass}`}
        ></button>
    ) : null;

    const logoEnabled = logo ? (
        <MainLogo customClass="bottom-logo" />
    ) : null;

    const realCustomClass = customClass || '';
    const pdfLink = 'https://www.lamoderna.com.mx/Promos/TyC_TE_Dinamica_WebAppHDA_Marzo2023.pdf';
    const termsLink = `https://docs.google.com/viewer?url=${encodeURIComponent(pdfLink)}`;

    return (
        <div className={`bottom-buttons-new ${realCustomClass}`}>
            <a className="linkTyC" rel="noopener noreferrer" href={termsLink} target="_blank">Términos y condiciones</a>
            {logoEnabled}
            {btn1Enabled}
        </div>
    );
};

export default BottomButtonsNew;
