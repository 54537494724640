import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../reducers/_dispatchers';
import PrizeKitCinema from '../PrizeKitCinema/PrizeKitCinema';
import Postal from '../Postal/Postal';
import Recipe from '../Recipe/Recipe';
import { prizeCatalog } from '../../constants/prizes';
import { getPostalURL } from '../../constants/assets';
import { forceScreen, mockData } from '../../constants/config';

class PrizeWinned extends Component {
    constructor(props) {
        super(props);
        const { Account: { playedTimes, profile, windedPrize }, match: { params } } = props;
        const isPostal = params && params.postalID && typeof params.postalID !== 'undefined' ?
        params.postalID : false;
        const isRecipe = params && params.recetaID && typeof params.recetaID !== 'undefined' ?
        params.recetaID : false;
        this.state = {
            isPostal,
            isRecipe,
            playedTimes,
            profile,
            windedPrize: forceScreen
                ? mockData[forceScreen]
                : windedPrize
        };
    }

    componentWillMount() {
        const { isPostal, isRecipe, windedPrize } = this.state;
        if(windedPrize) {
            this.props.resetPrize();
        } else if(!isPostal && !isRecipe) {
            this.props.history.push('/play');
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    goToPlaySection = () => {
        this.props.history.push('/play');
    }

    getContent = () => {
        const { isPostal, isRecipe, playedTimes, profile, windedPrize } = this.state;
        let content = null;
        let urlImage;

        if(isPostal) {
            urlImage = window.location.origin + getPostalURL(parseInt(isPostal));
            // const uriShare = window.location.href;
            content = (
                <div className="prize-winned-page">
                    <Postal number={parseInt(isPostal)} goToPlaySection={this.goToPlaySection.bind(this)} urlImage={urlImage} />
                </div>
            );
        } else if(isRecipe) {
            content = (
                <div className="prize-winned-page">
                    <Recipe number={parseInt(isRecipe)} goToPlaySection={this.goToPlaySection.bind(this)} />
                </div>
            );
        } else if(windedPrize && windedPrize.promocion) {
            const { promocion } = windedPrize;
            const { id: idPrize } = promocion;
            const prizeName = prizeCatalog[parseInt(idPrize)];
            // const prizeName = prizeCatalog[5];

            switch (prizeName) {
                case prizeCatalog[1]:   // Amazon
                case prizeCatalog[2]:   // Netflix
                case prizeCatalog[8]:   // Spotify
                    const { trivia } = windedPrize;
                    content = (
                        <div id="confetiContenedor" className="prize-winned-page">
                            <PrizeKitCinema goToPlaySection={this.goToPlaySection.bind(this)} playedTimes={playedTimes} prizeName={prizeName} profile={profile} trivia={trivia} idPrize={idPrize} />
                        </div>
                    );
                    break;
                case prizeCatalog[3]:   // Tip
                    const number = Math.floor(Math.random() * 20) + 1;
                    console.log(number, 'number');
                    urlImage = window.location.origin + getPostalURL(parseInt(number));
                    content = (
                        <div className="prize-winned-page">
                            <Postal number={number} urlImage={urlImage} goToPlaySection={this.goToPlaySection.bind(this)} />
                        </div>
                    );
                    break;
                case prizeCatalog[4]:   // Receta
                    content = (
                        <div className="prize-winned-page">
                            <Recipe goToPlaySection={this.goToPlaySection.bind(this)} />
                        </div>
                    );
                    break;
                default:
                    break;
            }
        }

        return content;
    }

    render() {
        return this.getContent();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrizeWinned);
