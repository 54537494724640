
export const dinamicaCerrada = false;
export const mensajeCerrada = 'La actividad ha finalizado, espera otra dinámica';

export const mockData = {
    TIP: {
        "promocion": {
            "id": "3",
            "nombre": "Tip",
            "condicion": "0",
            "limite": null,
            "created_at": "2018-11-28 15:37:03"
        }
    },
    RECETA: {
        "promocion": {
            "id": "4",
            "nombre": "Receta",
            "condicion": "0",
            "limite": null,
            "created_at": "2018-11-28 15:37:03"
        }
    },
    KIT: {
        "promocion": {
            "id": "2",
            "nombre": "Netflix",
            "condicion": "0",
            "limite": null,
            "created_at": "2018-11-28 15:37:03"
        }
    },
    TARJETA: {
        "promocion": {
            "id": "1",
            "nombre": "Amazon",
            "condicion": "0",
            "limite": null,
            "created_at": "2018-11-28 15:37:03"
        }
    },
    trivia: {
        pregunta: 'Cuanto es 2 + 2?',
        json_opciones: {
            a: '4',
            b: '8'
        }
    }
};
export const screens = {
    TIP: 'TIP',
    RECETA: 'RECETA',
    KIT: 'KIT',
    TARJETA: 'TARJETA',
    FORM_KIT: 'FORM_KIT',
    FORM_TARJETA: 'FORM_TARJETA'
}
export const forceScreen = false;
// export const forceScreen = screens.TARJETA;
