import { actionRegisterIn } from '../actions/loginActions';
import { setError } from '../reducers/_dispatchers';

const cantLoginIt = ()=> {
    setError();
};

const getMyFbData = ()=> {
    window.FB.api('/me', {fields: 'name,last_name,email'}, function(response) {
        actionRegisterIn(response);
    });
}

export const fbCheckLogin = ()=> {
    if(typeof window.FB !== 'undefined') {
        setTimeout(() => {
            window.FB.getLoginStatus(function(response) {
                if(typeof response === 'object'
                && typeof response.status === 'string'
                && response.status === 'connected') {
                    getMyFbData();
                } else {
                    cantLoginIt();
                }
            });
        }, 300);
    } else {
        setTimeout(() => {
            fbCheckLogin();
        }, 500);
    }
};

export const fbLogin = ()=> {
    if(typeof window.FB !== 'undefined') {
        const cHost = window.location.host;
        const urlToBack = cHost.indexOf('harinadearroz.com.mx') > -1
            ? 'https://harinadearroz.com.mx/login.php'
            : (cHost.indexOf('localhost:3000') > -1
            ? 'https://localhost:3000/public/login.php'
            : 'https://harinadearroz.heliko.space/login.php');

        /* const appID = cHost.indexOf('harinadearroz.com.mx') > -1 || cHost.indexOf('harinadearroz.heliko.space') > -1
            ? '744328973106700'
            : '744328973106700'; */

        window.location.href = urlToBack; // `https://www.facebook.com/dialog/oauth/?client_id=${appID}&redirect_uri=${urlToBack}&locale=es_LA&scope=public_profile,email`;
    } else {
        setTimeout(() => {
            fbLogin();
        }, 500);
    }
};

// 2390667201249265 Socialand
// 744328973106700 pidgey
// window.FB.getLoginStatus(function(response) {console.log(response);});
// window.FB.api('/me', {fields: 'name,last_name,email'}, function(response) { console.log(response); });
